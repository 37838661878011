import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../recoil/atoms/Clients';
import { currentUserAtom } from '../recoil/atoms/Auth';
import { SeverityLevel } from '@sentry/react';
import { useCallback } from 'react';

export interface ApiErrorInfo {
  error?: string;
  response?: AxiosResponse;
}

export interface ErrorDetails {
  dialog?: boolean;
  component?: string;
  breadcrumbs?: Sentry.Breadcrumb[];
  apiError?: ApiErrorInfo;
  level?: SeverityLevel;
  fn?: string;
}

const DEFAULT = {
  dialog: false,
  breadcrumbs: [] as Sentry.Breadcrumb[],
  level: 'info' as SeverityLevel,
};

interface ReturnType {
  captureError: (error: Error, details: ErrorDetails) => void;
  captureApiError: (apiError: ApiErrorInfo) => void;
}

const useSentry = (): ReturnType => {
  const user = useRecoilValue(currentUserAtom);
  const client = useRecoilValue(currentClientAtom);

  /**
   * Captures Sentry exception with added details
   * @param error exception sent to Sentry
   * @param details extra details to send with exception
   * @example
   * details
   * {
   *  dialog: boolean = false
   *  component: string
   *  breadcrumbs: Sentry.Breadcrumb[] = []
   *  httpCode: number | null
   *  level: Sentry.Severity = Sentry.Severity.Info
   *  fn: string
   * }
   */
  const captureError = useCallback(
    (error: Error, details: ErrorDetails = DEFAULT) => {
      const { width, height } = window.screen;
      const { dialog, component, breadcrumbs, apiError, level, fn } = {
        ...DEFAULT,
        ...details,
      };

      const tags = {
        resolution: `${width} x ${height}`,
        component,
        fn,
      };

      Sentry.captureException(error, (scope) => {
        Object.entries(tags).forEach(([key, value]) => {
          if (value) scope.setTag(key, value);
        });

        if (apiError) scope.setExtra('API Error', apiError);

        scope.setLevel(level);

        breadcrumbs.forEach((bc) => scope.addBreadcrumb(bc));

        // will trigger Sentry error dialog
        if (dialog) scope.setExtra('Dialog', true);

        // set user details
        scope.setUser({
          id: user?.id,
          ['Client ID']: client?.id,
        });

        return scope;
      });
    },
    [client?.id, user?.id],
  );

  const captureApiError = useCallback(
    (apiError: ApiErrorInfo) => {
      captureError(new Error('API Error'), {
        apiError,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        level: 'error',
      });
    },
    [captureError],
  );

  return {
    captureError,
    captureApiError,
  };
};

export default useSentry;
