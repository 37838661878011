import { ReactElement, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import PageLoader from './components/shared/page-loader/PageLoader';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { currentUserAtom } from './recoil/atoms/Auth';
import { currentClientAtom, hideClientsAtom, myClientsAtom } from './recoil/atoms/Clients';
import ClientService from './services/ClientService';
import { Roles } from './models/Role';
import { themeAtom } from './recoil/atoms/Theme';
import ErrorPage from './components/error/ErrorPage';
import ErrorType from './models/Error';
import ThemeUtils from './utils/ThemeUtils';
import IntercomUtils from './utils/IntercomUtils';
import { goToClientSpacePath } from './utils/NavigationUtils';
import { AutoLayout } from './layouts/Layout';
import { currentRouteAtom } from './recoil/atoms/Layout';
import { inAdminSpaceAtom } from './recoil/atoms/Workspace';
import CurrentUserService from './services/CurrentUserService';
import { useNavigateRef } from './hooks/useNavigateRef';
import useErrorInterceptor from './hooks/useErrorInterceptor';
import usePermissions from './hooks/permissions/usePermissions';
import { useCurrentRoute } from './hooks/useCurrentRoute';
import { systemDefaultLanguageCode } from './types/Languages';
import Router from './Router';
import { SignalRProcessesProvider } from './contexts/signalR/ProcessesContext';
import { FlagsProvider } from './contexts/FeatureFlagContext';
import { FeatureFlags } from './features/FeatureFlag';
import FeatureFlagService from './services/FeatureFlagService';
import { usePbkAuth } from './contexts/PbkAuthContext';
import { useTranslation } from 'react-i18next';
import { ProductFruits } from 'react-product-fruits';
import useFallbackLanguage from './hooks/useFallbackLanguage';

const { UNKNOWN_ERROR, APP_UPDATED } = ErrorType;

const App = (): ReactElement => {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserAtom);
  const currentClient = useRecoilValue(currentClientAtom);
  const setHideClientsNav = useSetRecoilState(hideClientsAtom);
  const setInAdminSpace = useSetRecoilState(inAdminSpaceAtom);
  const location = useLocation();
  const { match: routeMatch, route: currentRoute } = useCurrentRoute();
  const setCurrentRoute = useSetRecoilState(currentRouteAtom);
  const { isAuthenticated, refreshToken } = usePbkAuth();

  const [appReady, setAppReady] = useState(false);
  const navigate = useNavigateRef();
  const currentTheme = useRecoilValue(themeAtom);
  const hasPermission = usePermissions();
  const setMyClients = useSetRecoilState(myClientsAtom);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(null!);
  const firstLoadCalled = useRef(false);
  const { i18n } = useTranslation();

  const fallbackLanguage = useFallbackLanguage();

  useEffect(() => {
    if (firstLoadCalled.current || currentRoute?.publicPage) return;

    firstLoadCalled.current = true;
    refreshToken();
  }, [currentRoute?.publicPage, refreshToken]);

  useEffect(
    function setCurrentRouteState() {
      setCurrentRoute(currentRoute);
    },
    [currentRoute, setCurrentRoute],
  );

  useEffect(() => {
    if (currentTheme) {
      ThemeUtils.applyTheme(currentTheme);
    }
  }, [currentTheme]);

  useEffect(() => {
    IntercomUtils.setUserDetails(currentUser, currentClient);
  }, [currentClient, currentUser]);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    setInAdminSpace(location.pathname.startsWith('/admin/iam'));
  }, [location.pathname, setInAdminSpace]);

  const isConsultant = useMemo(() => hasPermission(Roles.Consultant), [hasPermission]);

  // Set currentUser atom
  useEffect(
    function appInit() {
      if (!isAuthenticated) {
        setAppReady(true);
        return;
      }

      FeatureFlagService.getFeatureFlagStatusses()
        .then((res) => setFeatureFlags(res.data.flags))
        .catch(() => setFeatureFlags({ moduleSharing: false, dataExport: false, clientContacts: false, distributionList: false }));

      CurrentUserService.getProfile()
        .then((res) => {
          localStorage.removeItem('auth-user');
          const profile = res.data;
          const locale = profile?.language || systemDefaultLanguageCode;
          i18n.changeLanguage(locale);

          setCurrentUser(profile);

          setAppReady(true);

          ClientService.getMyClients().then((res) => {
            setMyClients(res.data);

            /*
             * Hide Clients nav menu item
             * when not a consultant+
             * and when only have one client
             */
            if (!isConsultant && res.data.length === 1) {
              setHideClientsNav(true);
              if (routeMatch?.params.clientId !== res.data[0].id) {
                navigate.current(goToClientSpacePath(res.data[0], profile), { replace: true });
              }
            } else {
              setHideClientsNav(false);
            }
          });
        })
        .catch(() => {
          // show error message
          setAppReady(true);
        });
    },
    [i18n, isAuthenticated, isConsultant, navigate, routeMatch?.params.clientId, setCurrentUser, setHideClientsNav, setMyClients],
  );

  useErrorInterceptor();

  const userInfo = useMemo(() => {
    if (currentUser && currentClient) {
      return {
        username: currentUser.id,
        email: currentUser.email,
        firstname: currentUser.firstName,
        lastname: currentUser.lastName,
        props: {
          company: currentClient.name,
          environment: window.location.hostname,
        },
      };
    }
    return null;
  }, [currentUser, currentClient]);

  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setUser({
          id: currentUser?.id,
          clientId: currentClient?.id,
        });
        return scope;
      }}
      fallback={(props) => {
        const isAppUpdated = props.error.message.includes('Failed to fetch dynamically imported module');
        return (
          <AutoLayout>
            <ErrorPage
              type={isAppUpdated ? APP_UPDATED : UNKNOWN_ERROR}
              showRefresh
              showContactSupport
              goBackCallback={() => {
                const timeout = setTimeout(() => {
                  props.resetError();
                  clearTimeout(timeout);
                }, 500);
              }}
            />
          </AutoLayout>
        );
      }}
    >
      <PageLoader loading={!appReady}>
        <SignalRProcessesProvider url={`${import.meta.env.VITE_API_BASE_URL}/hubs/processes`}>
          <div className={`flex h-screen bg-white font-sans text-black ${currentTheme?.name}`}>
            <Suspense fallback={<PageLoader loading isSuspense />}>
              <AutoLayout>
                <FlagsProvider featureFlags={featureFlags}>
                  <Router />
                </FlagsProvider>
              </AutoLayout>
              {import.meta.env.VITE_PRODUCT_FRUIT_WORKSPACE_CODE && (
                <ProductFruits
                  key={userInfo?.username}
                  workspaceCode={import.meta.env.VITE_PRODUCT_FRUIT_WORKSPACE_CODE}
                  language={currentUser?.language || fallbackLanguage}
                  user={userInfo || { username: 'unknown' }}
                />
              )}
            </Suspense>
          </div>
        </SignalRProcessesProvider>
      </PageLoader>
    </Sentry.ErrorBoundary>
  );
};

export default App;
