import { useMemo, useRef } from 'react';
import {
  FloatingArrow,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useRecoilState } from 'recoil';
import { activeTooltipAtom } from '../recoil/atoms/App';

export const useTooltip = (uniqueId: string, ariaRole: 'tooltip' | 'label' = 'tooltip') => {
  const arrowRef = useRef(null);
  const [activeTooltip, setActiveTooltip] = useRecoilState(activeTooltipAtom);
  const isOpen = useMemo(() => activeTooltip === uniqueId, [activeTooltip, uniqueId]);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (open) => {
      if (open) {
        setActiveTooltip(uniqueId);
      } else {
        if (activeTooltip === uniqueId) {
          setActiveTooltip(null);
        } else {
          setActiveTooltip(uniqueId);
        }
      }
    },
    strategy: 'fixed',
    placement: 'bottom',
    middleware: [
      offset(10),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
      size({
        padding: 10,
        apply({ availableWidth, elements, rects }) {
          const maxWidth = Math.min(availableWidth, window.innerWidth / 3);
          elements.floating.style.maxWidth = `${maxWidth}px`;

          // Only for really small tooltips to we give them some room
          if (rects.floating.width < 50) {
            elements.floating.style.minWidth = '175px';
          }
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    role: ariaRole,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  const arrowEl = useMemo(() => {
    return <FloatingArrow ref={arrowRef} context={context} className="fill-primary-1 " />;
  }, [context]);

  return {
    anchorProps: { ref: refs.setReference, tabIndex: 0, ...getReferenceProps() },
    floatingProps: {
      ref: refs.setFloating,
      ...getFloatingProps({
        style: {
          wordBreak: 'break-word',
          hyphens: 'auto',
          zIndex: 9999,
          ...floatingStyles,
        },
      }),
    },
    arrowEl,
    anchorRef: refs.reference,
    floatingRef: refs.floating,
  };
};
