import { createContext, ReactNode, useContext } from 'react';
import { FCWithChildren } from '../types/FCWithChildren';
import { FeatureFlag, FeatureFlags } from '../features/FeatureFlag';

type FlagsProviderProps = {
  featureFlags: FeatureFlags;
};

type FlagsProps = {
  authorizedFlags: FeatureFlag[];
  exactFlags?: boolean;
  renderOn?: (matchingFlags: FeatureFlag[]) => ReactNode;
  renderOff?: (matchingFlags: FeatureFlag[]) => ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const FeatureFlagsContext = createContext<FlagsProviderProps>(null!);
export const useFeatureFlags = (): FlagsProviderProps => useContext(FeatureFlagsContext);

export const Feature: FCWithChildren<FlagsProps> = (props) => {
  const { authorizedFlags, exactFlags, renderOn, renderOff, children } = props;

  const matchingFlags = (flags: FeatureFlags) => {
    const result = Object.entries(flags)
      .filter(([key, value]) => authorizedFlags.includes(key) && value)
      .map(([key]) => key);
    return result;
  };

  const resolveRender = (matchingFlags: FeatureFlag[]) => (children ? children : renderOn && renderOn(matchingFlags));

  return (
    <FeatureFlagsContext.Consumer>
      {(flags) => {
        const matchedFlags = matchingFlags(flags.featureFlags);
        if (exactFlags) {
          return matchedFlags.length === authorizedFlags.length ? resolveRender(matchedFlags) : renderOff && renderOff(matchedFlags);
        } else {
          return matchedFlags.length ? resolveRender(matchedFlags) : renderOff && renderOff(matchedFlags);
        }
      }}
    </FeatureFlagsContext.Consumer>
  );
};

export const FlagsProvider: FCWithChildren<FlagsProviderProps> = ({ featureFlags: value, children }) => (
  <FeatureFlagsContext.Provider value={{ featureFlags: value }}>{children}</FeatureFlagsContext.Provider>
);
