import { useMemo } from 'react';
import { FormType } from '../models/FormTypes';
import { ActivityType, ClientFormStatus, documentStatusKeys, formStatusKeys } from '../models/ClientFormStatus';
import { useTranslation } from 'react-i18next';

const useStatusText = (activity: ActivityType) => {
  const { t } = useTranslation(['common', 'module']);
  const requiresApproval = 'requiresApproval' in activity ? activity.requiresApproval : activity.form.requiresApproval;
  const statusText = useMemo(() => {
    if (activity.archivedUtc) {
      return t(activity.type === FormType.Document ? 'common:form-status.archived-documents' : 'common:form-status.archived');
    }

    if (activity.status === ClientFormStatus.Completed && requiresApproval) {
      return t('common:form-status.approved');
    }

    return activity.type === FormType.Document ? t(documentStatusKeys[activity.status]) : t(formStatusKeys[activity.status]);
  }, [activity.archivedUtc, requiresApproval, activity.status, activity.type, t]);

  return statusText;
};

export default useStatusText;
