import { FC, ReactNode, ReactElement, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useTooltip } from '../../hooks/useTooltip';
import { FloatingPortal } from '@floating-ui/react';
import { v4 as uuid } from 'uuid';
import { useRecoilValue } from 'recoil';
import { activeTooltipAtom } from '../../recoil/atoms/App';

type TooltipProps = {
  text: ReactElement | ReactNode | string | number | boolean | null | undefined;
  truncatedTextMode?: boolean; // when true, search for truncated text, and only show the tooltip if found

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (props: Record<string, unknown>) => ReactElement | HTMLElement;
  style?: 'dark' | 'light';
};

const Tooltip: FC<TooltipProps> = (props) => {
  const { text, truncatedTextMode, children, style = 'dark' } = props;
  const [blockShowing, setBlockShowing] = useState(false);
  const tooltipId = useRef(uuid());
  const activeTooltipId = useRecoilValue(activeTooltipAtom);
  const active = useMemo(() => tooltipId.current === activeTooltipId, [activeTooltipId]);

  const { anchorProps, floatingProps, anchorRef, arrowEl } = useTooltip(tooltipId.current);

  const updateBlockShowing = useCallback(() => {
    const anchor = anchorRef.current as HTMLElement;
    if (!anchor) {
      setBlockShowing(false);
      return;
    }

    if (!truncatedTextMode) {
      setBlockShowing(false);
      return;
    }

    let truncatedTextEls = [...anchor.querySelectorAll('.truncate'), ...anchor.querySelectorAll('[class*="line-clamp-"]')];
    if ([...anchor.classList].some((x) => x.indexOf('truncate') || x.indexOf('line-clamp-') > -1)) {
      truncatedTextEls = [...truncatedTextEls, anchor];
    }

    const anyTruncated = truncatedTextEls.some((x) => {
      const rect = x.getBoundingClientRect();
      return Math.ceil(rect.width) < x.scrollWidth || Math.ceil(rect.height) < x.scrollHeight;
    });
    setBlockShowing(!anyTruncated);
  }, [anchorRef, truncatedTextMode]);

  useEffect(() => {
    const anchor = anchorRef.current;

    if (!anchor || !(anchor instanceof HTMLElement)) {
      setBlockShowing(false);
      return;
    }

    const handler = () => updateBlockShowing();

    handler();

    const resizeObserver = new ResizeObserver(handler);
    resizeObserver.observe(anchor);

    return () => {
      resizeObserver.disconnect();
    };
  }, [anchorRef, updateBlockShowing]);

  let styleCss = 'text-white bg-primary-1 border-primary-1';
  switch (style) {
    case 'dark':
      styleCss = 'text-white bg-primary-1 border-primary-1';
      break;
    case 'light':
      styleCss = 'text-primary-1 bg-white border-gray-4';
      break;
  }

  return (
    <>
      {children(anchorProps)}
      {typeof text !== 'boolean' && !!text && active && !blockShowing && (
        <FloatingPortal id="tooltip-root">
          <div
            data-tooltip={tooltipId}
            className={`${style} ${styleCss} border-1 override-link-colour rounded-md px-3 py-2 shadow-md`}
            {...floatingProps}
          >
            {arrowEl}
            {text}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export default Tooltip;
